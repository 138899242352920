#event-container {
    padding: 1em 0;
    margin-bottom: 3em;
    /* min-height: 67vh; */
}

#events {
    height: 100%;
    display: flex;
    flex-flow: column;
    padding: 1em 2em;
}

#loading-div {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-event-div {
    display: flex;
    padding: 5em 1em;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-align: center;
}

.empty-event-div p {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.7);
}

#cal-nav {
    justify-content: center;
    width: 325px;
    max-width: 90vw;
    margin: 0 auto;
}

#cal-nav a, #cal-nav a:active {
    font-size: 1.3em;
    text-decoration: none;
    color: #0000EE;
}

#cal-nav h3 {
    font-size: 1.6em;
    font-weight: lighter;
    margin: 0 1em;
}

.loading {
    width: 100px;
    transform: translateX(-1);
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.event-title {
    font-size: 2em;
    font-weight: 400;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.arrow-btn {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfc;
    border: none;
    outline: none;
    width: 36px;
    height: 36px;
    margin: 0 5px;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.eventBanner-bg-image h1 {
    font-size: 2.8em;
    padding: 0 0.5em;
    color: white;
}

.event-contact-image {
    height: 30px;
}

.event-title-container {
    min-height: 100px;
}

.contact-text {
    font-size: 19px;
    font-weight: bold;
}

@media only screen and (min-width: 730px) {
    #event-container {
        width: 50vw;
    }
}