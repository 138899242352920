#navbar {
    position: absolute;
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: transparent;
    padding: 0.7em;
}

.desktop {
    display: none;
}

.menu-option-container {
    width: 36px;
    height: 36px;
}

.menu-icon {
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 730px) {
    .desktop {
        display: block;
    }
    
    .menu-option-container {
        width: auto;
        height: auto;
    }

    .desktop {
        text-decoration: none !important;
        font-weight: 100;
        color: white;
    }

    a { 
        text-decoration: none !important; 
        font-weight: 100;
        color: white;
    }

    .desktop.active {
        background: -webkit-linear-gradient(122deg, #f152ff, #FF9815);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    a:link { text-decoration: none; }

    a:visited { text-decoration: none; }

    a:hover { text-decoration: none; }

    a:active { text-decoration: none; }
    
    .menu-icon-container {
        display: none;
    }
}
