.banner-img-container {
    width: 100vw;
    height: 250px;
    align-items: center;
    position: relative;
}

#banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg, 
        rgba(0, 0, 0, 0.6) 0%, 
        rgba(0, 0, 0, 0.4) 40%, 
        rgba(0, 0, 0, 0) 100%
    );
}

.banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.position-absolute {
    position: absolute;
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.banner-title {
    font-size: 5.1em;
}