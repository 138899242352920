* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#main {
    transition: 0.5s;
}

/* body * { outline: 1px solid red; } */

.form-container  {
    width: 100%;
    max-width: 85vw;
}

.form-signup {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

/* STANDARDIZED STYLES */

.text-center {
    text-align: center;
}

.disabled-text {
    color: #878787;
}

.font-weight-light {
    font-weight: lighter;
}

.f-24 {
    font-size: 24px;
}

.txt-color-white {
    color: white;
}

.txt-color-black {
    color: black;
}

.txt-no-decor {
    text-decoration: none;
}

.text-bold {
    font-weight: bold;
}

.btn {
    padding: .5em; 
    font-size: 30px;
    background: linear-gradient(122deg, #f152ffb0, #ff971fc0);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
}

.btn:hover, .btn:focus {
    border: none;
    outline: none;
    transform: translateY(-2px);
}

.position-absolute {
    position: absolute;
}

.left-10 {
    left: 10%;
}

.bottom-10 {
    bottom: 10%;
}

.title-theme {
    background: -webkit-linear-gradient(122deg, #f152ff, #FF9815);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rotate-90 {
    transform: rotate(-90deg);
}

.rotate-180 {
    transform: rotate(-180deg);
}

.transition-2 {
    transition: 0.2s all ease-in-out;
}

.transition-4 {
    transition: 5s;
    transition-timing-function: cubic-bezier(0.1, 0.3, 0.1);
}

.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.div-center {
    margin: 0 auto;
}

.center-v {
    align-items: center;
}

.container-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1080px;
    margin: 0 auto;
}

.d-block {
    display: block;
}

.ovf-hidden {
    overflow: hidden;
}

.p-relative {
    position: relative;
}

.v-flex-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space {
    justify-content: space-evenly;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.w-v85 {
    width: 85vw;
    max-width: 320px;
}

.vw-70 {
    width: 70vw;
}

.px-2 {
    padding-right: .5em;
    padding-left: .5em;
}

.px-3 {
    padding-right: .75em;
    padding-left: .75em;
}

.px-4 {
    padding-right: 1em;
    padding-left: 1em;
}

.p-2 {
    padding: .5em;
}

.pb-4 {
    padding-bottom: 1em;
}

.pb-2 {
    padding-bottom: 0.5em;
}

.pb-10 {
    padding-bottom: 10em;
}

.py-4 {
    padding-top: 1em;
    padding-bottom: 1em;
}

.py-3 {
    padding-top: .75em;
    padding-bottom: .75em;
}

.py-2 {
    padding-top: .5em;
    padding-bottom: .5em;
}

.p-4 {
    padding: 1em;
}

.my-4 {
    margin-top: 1em;
    margin-bottom: 1em;
}

.ml-1 {
    margin-left: 0.5em;
}

.h-0 {
    max-height: 0;
}

.h-auto {
    height: auto;
}

.h-100v {
    height: 100vh;
}

.mh-600 {
    height: 600px;
}

.mh-300 {
    max-height: 300px;
}

.br-bottom-8 {
    border-radius: 0 0 5px 5px;
}

.br-top-8 {
    border-radius: 5px 5px 0 0;
}

.ovf-hidden {
    overflow: hidden;
}

.form-label {
    margin-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #cecece;
}

.btn-hover:hover {
    cursor: pointer;
}

.form-input {
    width: 100%;
    padding: 10px 0px;
    margin-top: 20px;
    border: none;
    outline: none;
    text-align: left;
    color: #dedede;
    background-color: transparent;
}

.form-input::placeholder {
    opacity: 0;
}

.form-span {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(30px);
    font-size: 0.825em;
    transition-duration: 300ms;
    color: #cecece;
}

.form-button {
    padding: 8px 0px; 
    margin-top: 20px;
    font-size: 20px;
    background: linear-gradient(122deg, #f152ff3e, #ff971f3e);
    color: #fff;
    text-decoration: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.form-button:hover, .form-button:focus {
    border: none;
    outline: none;
    transform: translateY(-2px);
}

.form-label:focus-within > span,
.form-input:not(:placeholder-shown) + span{
    background: -webkit-linear-gradient(122deg, #f152ff, #FF981F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform:translateY(0px);
}

.form-response-success {
    border-radius: 5px;
    padding: 1em;
    width: 100%;
    text-align: center;
    background-color: rgba(25, 150, 25, 0.322);
    color: green;
}

.form-response-failure {
    border-radius: 5px;
    padding: 1em;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 0, 0, 0.199);
    color: red;
}

@media only screen and (min-width: 730px) {
    .footer-flex {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }
    .footer-div {
        width: 50%;
        max-width: 300px;
        margin: 0 30px;
    }
    .footer-div div {
        width: 100%;
    }
    .footer-img-container {
        padding: 0;
    }
}

/* TESTING CLASSES */
.test-red {
    border: .5px solid red;
}

.test-blue {
    border: .5px solid blue;
}

.test-green {
    border: .5px solid green;
}

.test-purple {
    border: .5px solid purple;
}