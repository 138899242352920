#faq-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 2em 0;
}

.qa-box {
    width: 100%;
    margin: 0 1em;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    border-bottom: 2px solid rgba(0,0,0,0.2);
}

.qa-box:first-of-type {
    border-radius: 10px 10px 0 0;
}

.qa-box:last-of-type {
    border-radius: 0 0 10px 10px;
    border-bottom: none !important;
}

.qa-box.active {
    background-color: #f152ff11;
}

.question-container {
    padding: 1em 0.7em 0.5em 0.7em;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.question-container:last-of-type {
    border: none !important;
}

.question {
    transition: all 0.3s ease;
}

.question.active {
    color: #FF9815;
}

.view-icon {
    transform: rotateZ(0deg);
    transition: all 0.3s ease;
    width: 30px;
    height: 30px;
    margin-left: 0.6em;
}

.view-icon.rotate45 {
    transform: rotateZ(45deg);
}

.answer-container {
    max-height: 0px;
    overflow: hidden;
    padding: 0 0.7em 0.5em 0.7em ;
    transition: all 0.3s ease;
}

.answer-container.answer-showing {
    max-height: 350px;
}

@media only screen and (min-width: 730px) {
    #faq-container {
        width: 60vw;
        padding: 0 4em;
    }

    .container {
        display: flex;
        justify-content: center;
    }

    .view-icon:hover {
        cursor: pointer;
    }

    .question {
        font-size: 24px;
    }

    .answer-container p {
        font-size: 20px;
    }
}