#landing-banner {
    height: 100vh;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 18%, rgba(0, 0, 0, 0) 100%), url('../../images/asset.landing-banner-mobile.JPG');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.landing-text {
    position: absolute;
}

.landing-text h1 {
    padding: 0.2em 0;
    color: white;
    font-weight: 400;
    font-size: 64px;
}

#selection-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1em 0;
}

.box-selection-container {
    width: 300px;
    height: 300px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin: 1em 0;
    transition: all 0.5s ease;
}

.box-selection-container a {
    color: white;
    border-radius: 4px;
    text-decoration: none !important;
}

.box-link {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 4px;
    position: relative;
}

.box-selection-text {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 0.25);
}

.box-selection-image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.15);
    object-fit: cover;
}

.events-btn {
    width: 100%;
    max-width: 100%;
}

@media only screen and (min-width: 730px) {
    #selection-boxes-container {
        display: flex;
        flex-wrap: wrap;
        width: 70vw;
        justify-content: center;
    }
    .box-selection-container {
        width: 400px;
        height: 400px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin: 1em
    }

    .box-selection-container:hover {
        transform: scale(1.04);
    }

    .landing-text {
        text-align: center;
    }

    .landing-text h1 {
        padding: 0.2em;
        font-size: 120px;
        font-weight: bold;
    }

    .calendar-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .calendar-content-container {
        padding: 2em;
    }
}