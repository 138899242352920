#people-container {
    height: fit-content;
    width: 100%;
    margin: 1em 0em;
}

.people-group-title {
    font-size: 36px;
    font-weight: lighter;
    padding: 0.3em;
}

@media only screen and (min-width: 730px) {
    #people-container {
        width: 65vw;
    }
}