#about {
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#about h1 {
    font-size: 2.7em;
}

#about p {
    font-size: 16px;
    text-align: justify;
}

@media only screen and (min-width: 730px) {
    .about-container {
        width: 55vw;
    }
}