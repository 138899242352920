.profile-large {
    width: 40vw;
    margin: 0.5rem;
    padding: 1em 0.5em;
    background-color: #fff;
    border-radius: 4px;
    /* border: 1px solid #434343; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.profile-large-data {
    padding: 0.5em 0;
}

.profile-large-img-container {
    width: 120px;
    height: 120px;
    align-items: center;
}

.profile-large-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-large h1 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.profile-large h2 {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #3e3e3e;
}

.profile-small {
    width: 80vw;
    height: fit-content;
    margin: 0.3em 0;
    padding: 0.5em 0.5em;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.profile-small-container {
    display: flex;
    justify-content: left;
}

.profile-small-img-container {
    width: 60px;
    height: 60px;
    align-items: center;
}

.profile-small-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-small-data-container {
    display: flex;
    align-items: center;
}

.profile-small-data {
    padding: 0 0.5em;
}

.profile-small h1 {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
}

.profile-small h2 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #3e3e3e;
}

@media only screen and (min-width: 730px) {
    .profile-large {
        width: 25vw;
    }

    .profile-small {
        width: 13vw;
        margin: 0.5em;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .profile-small-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .profile-small-data-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .profile-small-data {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .profile-small-data h1 {
        width: 100%;
        text-align: center;
    }

    .profile-small-data h2 {
        width: 100%;
        text-align: center;
    }

    .profile-small-img-container {
        width: 120px;
        height: 120px;
    }
}