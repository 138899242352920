.flex-center {
    display: flex;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.flex-left {
    display: flex;
    justify-content: left;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.width-100p {
    width: 100%;
}

.width-100vw {
    width: 100vw;
}

.border-blue {
    border: 1px solid blue;
}

.border-green {
    border: 1px solid green;
}

.border-red {
    border: 1px solid red;
}

.padding-bottom {
    padding-bottom: 2em;
}