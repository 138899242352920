.footer-flex {
    background: linear-gradient(
        to bottom, 
        #fff 0%, 
        #fff 90%,
        #bddbe9 100%
    );
    color: #202020;
    border-top: 1px solid #bddbe9;
}

.footer-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.footer-div div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-img {
    width: 100%;
    max-width: 300px;
}

.footer-img-container {
    padding: 2.8em 0;
    width: 100%;
    max-width: 85vw;
}

.copy {
    width: 100%;
    text-align: center;
    padding: 8px;
    color: #e2e2e2;
    background-color: #1b3551;
}