#infoSection {
    /* background-image: url('../Images/infobg.svg'); */
    width: 100%;
    height: 100%;
    background-repeat: repeat-x;
    background-size: cover;
    padding: 1em 0;
    min-height: 80vh;
}

#infoSection h3 {
    font-size: 1.4em;
}

.info-container {
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
}

.img-dropdown-container {
    width: 100%;
    height: 350px;
    transition: .3s all ease-in-out;
}

.img-dropdown-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.expand-contract {
    margin-top: -100%;
    transition: .3s ease-in-out;
    max-height: 0;
}

.expanded {
    margin-top: 0;
    max-height: 100vh;
}