.image-banner {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(256, 256, 256, 0.5)), url('../images/asset.havasupai.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.image-banner-titles {
    position: absolute;
    top: 10%;
    left: 8%;
}

.image-banner-titles h1 {
    /* font-size: 120px; */
    font-size: 60px;
    line-height: 1.2em;
    color: white;
}

.link {
    color: white;
    text-decoration: none;
}