.banner-background {
    background-color: #122138;
    color: #E8E8E8;
}

.banner-background-2 {
    background-color: white;
    background: -webkit-linear-gradient(122deg, #f152ff, #FF9815);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h-200 {
    height: 200px;
}

.h-600 {
    height: 600px;
}

.social-link {
    padding: 0 .75em;
}

.banner-background-2 h1, .banner-background h1 {
    font-size: 60px;
    font-weight: lighter;
    text-align: center;
}

.join-the-crew-hashtag {
    width: 80vw;
    max-width: 500px;
}